import React from 'react'
import { graphql } from 'gatsby'
import {
  reconstructGalleryItems,
  reconstructProductSlideFeatures,
} from '@utils/reconstructArrayObject'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import Ads10BestWebsites from '@components/global/ads/10BestWebsites'
import AdsCompareWebsites from '@components/global/ads/CompareWebsites'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  Gallery,
  Separator,
  FeaturesSlider,
  WebsiteFeatures,
} from '@components/pages/real-estate-websites'

import * as cx from './AgentPro.module.scss'

const ProductGalleryAgentPro = ({
  data: { page },
  pageContext: { seo, uri },
}) => {
  const product = page.product

  return (
    <LayoutInnerPage wrapperClassName={cx.wrapper}>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        bgGradientShow
        wrapperClassName={cx.banner}
        // bgGradient={cx.bgGradient}
        layout="product-gallery"
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.bannerForm?.title}
        description={page?.bannerForm?.description}
        devices={{
          layout: 'TabletLandscapeMobile',
          mobile: `${page?.bannerForm?.mobile?.url}`,
          tablet: {
            one: `${page?.bannerForm?.laptop?.url}`,
            two: `${page?.bannerForm?.tablet?.url}`,
          },
        }}
        formTitle={page?.bannerForm?.formTitle}
        formSubtitle={page?.bannerForm?.formSubtitle}
        formSubmitLabel={page?.bannerForm?.formSubmitLabel}
      />
      <Gallery
        wrapperClass={cx.gallery}
        title={product.gallery.title}
        description={product.gallery.description}
        posts={reconstructGalleryItems(product.gallery.items)}
        postsPerPage={6}
        loadMoreButtonTextDesktop={`View more ${page.title} websites`}
        loadMoreButtonTextMobile={`More ${page.title} websites`}
      />
      <Separator
        wrapperClassName={cx.separator}
        title="Kickstart Your <br />Online Presence"
        titleClassName="heading-5"
        description="Agent Pro websites are built for go-getter real estate agents who want a great-looking website ASAP. Make a stunning first impression with a pre-built design template that delivers on quality and style."
        descriptionClassName="default-body large"
      />
      <FeaturesSlider
        title={product?.kickstart?.branding?.title}
        items={reconstructProductSlideFeatures(
          product?.kickstart?.branding?.items
        )}
      />
      <FeaturesSlider
        title={product?.kickstart?.setup?.title}
        items={reconstructProductSlideFeatures(
          product?.kickstart?.setup?.items
        )}
        reverse={true}
        defaultIndex={1}
      />
      <WebsiteFeatures
        wrapperClassName={cx.websiteFeatures}
        bannerBg={page.productFeatures.background.url}
        title={page.title}
        headingClassName="heading-1"
        bannerLaptop={page.productFeatures.laptop.url}
        bannerMobile={page.productFeatures.mobile.url}
        bestForItems={page.productFeatures.bestFor}
        highlightsItems={page.productFeatures.highlights}
        featuresItems={page.productFeatures.more}
      />

      <div className="ads">
        <div className="container">
          <Ads10BestWebsites
            name={page.title}
            image={
              page.productAds.image.url !== null
                ? page.productAds.image.url
                : 'https://resources.agentimage.com/redesign/local/ads/ap-device.png'
            }
            imageWidth={
              page.productAds.image.url !== null
                ? page.productAds.image.width !== null
                : 510
            }
            imageHeight={
              page.productAds.image.url !== null
                ? page.productAds.image.height !== null
                : 391
            }
            link={page.productAds.link}
            invert={true}
          />
          <AdsCompareWebsites />
        </div>
      </div>

      <LeadContactForm
        version={page?.leadContactForm?.version}
        background={page?.leadContactForm?.background}
        modelOrBackground={page?.leadContactForm?.model}
        title={page?.leadContactForm?.title}
        subtitle={page?.leadContactForm?.subtitle}
        submitLabel={page?.leadContactForm?.submit}
        leadName={page?.title}
        layout="default"
        formLocation="footer"
      />
    </LayoutInnerPage>
  )
}

export default ProductGalleryAgentPro

export const pageQuery = graphql`
  query ProductGalleryAgentProPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }

      bannerForm {
        title
        description
        laptop {
          url
        }
        tablet {
          url
        }
        mobile {
          url
        }
        formTitle
        formSubtitle
        formSubmitLabel
      }

      product: productAgentPro {
        gallery {
          title
          description
          items {
            name
            url
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }

        kickstart {
          title
          description
          branding {
            title
            items {
              title
              image {
                url
              }
            }
          }
          setup {
            title
            items {
              title
              image {
                url
              }
            }
          }
        }
      }

      productFeatures {
        background {
          url
        }
        laptop {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        bestFor {
          check
          content
        }
        highlights {
          check
          content
        }
        more {
          check
          content
        }
      }

      productAds {
        background {
          url
        }
        image {
          url
          width
          height
        }
        link
      }

      leadContactForm {
        background {
          url
          srcset
          sizes
          width
          height
        }
        version
        model
        title
        subtitle
        submit
      }
    }
  }
`
